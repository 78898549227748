import axios from 'axios';
import { Toast } from 'vant';
import ClientApp from '@/client/index';
import UA from '@/client/ua';
import router from '@/router/.invoke/router';
import { getToken } from '@/core/cache/auth';
import { useUserStore } from '@/store/user';
import { getDeviceId, getFingerPrint } from './deviceId';

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['business-scope'] = 10;

// 请求拦截器
axios.interceptors.request.use(config => {
  const token = getToken();
  const deviceId = getDeviceId();
  const finger = getFingerPrint();
  const authorization =
    config.url.includes('sendCode') || config.url.includes('oauth/token')
      ? 'Basic dGVzdF9jbGllbnQ6dGVzdF9zZWNyZXQ='
      : `Bearer ${token}`;
  token && (config.headers.Authorization = authorization);
  deviceId && (config.headers['device-id'] = deviceId);
  finger && (config.headers['out-sign'] = finger);
  return config;
});

axios.interceptors.response.use(
  response => {
    if (response?.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error?.response?.status) {
      switch (error?.response?.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          Toast('登录已失效，请重新登录');
          // 清除token
          localStorage.removeItem('token');
          if (UA.androidClient) {
            ClientApp.switchAccount();
          } else {
            router.push('/login');
          }
          break;
        default:
          Toast.fail('服务器出错');
      }

      return Promise.reject(error);
    }
    Toast.fail('服务器出错');
    return Promise.reject(error);
  }
);
const http = {
  async get(url, data) {
    try {
      let res = await axios.get(url, { params: data });
      res = res.data;
      return new Promise(resolve => {
        if (res.code === '00000') {
          resolve(res);
        } else {
          resolve(res);
        }
      });
    } catch (err) {
      console.log(err);
    }
  },
  async post(url, data, isFormData = true) {
    let formData;
    if (isFormData) {
      formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
    } else {
      formData = data;
    }

    let res = await axios.post(url, formData);
    res = res.data;
    return new Promise((resolve, reject) => {
      if (['RBAC000', 'RBAC001', 'RBAC003'].includes(res.code)) {
        const userStore = useUserStore();
        userStore.logout();
        if (UA.androidClient) {
          ClientApp.switchAccount();
        } else {
          router.push('/login');
        }
      }
      if (res.code === '00000') {
        resolve(res);
      } else {
        reject(res);
        Toast.fail(res.message);
      }
    });
  }
};

export { http };
