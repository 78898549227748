const DEVICE_ID_KEY = 'DEVICE_ID_KEY';
const FINGER_PRINT_KEY = 'FINGER_PRINT_KEY';
import ClientApp from '@/client/index';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
export const getDeviceId = () => {
  return window.localStorage.getItem(DEVICE_ID_KEY) || null;
};

export const setDeviceId = deviceId => {
  return window.localStorage.setItem(DEVICE_ID_KEY, deviceId || '');
};

export const saveDeviceAsync = async () => {
  const deviceId = getDeviceId();

  if (!deviceId) {
    ClientApp.getDeviceId().then(res => {
      if (res.deviceId) {
        setDeviceId(res.deviceId);
      }
    });
  }
};

export const getFingerPrint = () => {
  return window.localStorage.getItem(FINGER_PRINT_KEY) || null;
};

export const setFingerPrint = finger => {
  return window.localStorage.setItem(FINGER_PRINT_KEY, finger || '');
};
//调用必须保证
export const generateFingerPrint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const { osCpu, cpuClass, canvas, vendorFlavors, vendor } = result.components;
  const res = FingerprintJS.hashComponents({
    osCpu,
    cpuClass,
    canvas,
    vendorFlavors,
    vendor
  });
  console.log(res);
  setFingerPrint(res);
  return res;
};
